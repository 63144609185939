import { type SubmitHandler, useForm } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'
import cn from 'classnames'
import dayjs from 'dayjs'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { useWizard } from 'react-use-wizard'
import { useEffect } from 'react'

import { useFormStore } from '../../../stores/form.store'
import { schemaDatosPersonales } from '../../../validation/steps'
import { DateInput } from '../../../components/DateInput'
import { type Solicitante } from '../../../types'
import { capitalizeLetters } from '../../../utils'

type FormData = Pick<Solicitante,
'Nombres' |
'TipoIdentificacion' |
'Identificacion' |
'FechaNacimiento' |
'Celular' |
'Correo'
>

export const DatosPersonales = (): JSX.Element | null => {
  const updateSolicitante = useFormStore(state => state.updateSolicitante)
  const currentSolicitante = useFormStore(state => state.getCurrentSolicitante())
  const solicitantes = useFormStore(state => state.solicitantes)

  const { handleStep } = useWizard()

  const {
    register,
    formState: { errors },
    setValue,
    getValues,
    setError,
    handleSubmit,
    reset
  } = useForm<FormData>({
    resolver: joiResolver(schemaDatosPersonales),
    defaultValues: {}
  })

  const onSubmit: SubmitHandler<FormData> = (data): void => {
    updateSolicitante(data)
  }

  handleStep(async () => {
    await new Promise((resolve) => {
      const currentId = getValues('Identificacion')
      const solicitantesArray = Object.values(solicitantes)

      if (solicitantesArray.length > 1) {
        const isDuplicate = solicitantesArray
          .find(({ Identificacion = '', Nombres = '' }) =>
            (Identificacion === currentId) && (Nombres !== currentSolicitante.Nombres))

        if (isDuplicate && (currentSolicitante.Nombres !== isDuplicate.Nombres)) {
          setError('Identificacion', {
            type: 'custom',
            message: `Identificaciones duplicadas (${isDuplicate.Nombres.substring(0, 15)}...)`
          })
          throw new Error('Identificacion Duplicada')
        }
      }

      void handleSubmit(
        async (data) => {
          onSubmit(data)
          resolve(data)
        })()
    })
  })

  useEffect(() => {
    if (currentSolicitante) {
      reset(currentSolicitante)
    }
  }, [currentSolicitante])

  return (
    <div className='py-8 px-10'>

        <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
            <div className="form-control">
                <span className='label-text font-bold text-md mb-2'>Nombres y Apellidos Completos *</span>
                <input
                    type="text"
                    placeholder="Tu nombre completo"
                    className={cn('input input-bordered bg-white w-full capitalize', { 'input-error': errors.Nombres })}
                    // value={getValues('Nombres')}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const nameCap = capitalizeLetters(e.target.value)
                      setValue('Nombres', nameCap)
                    }
                    }
                />

                {
                    errors.Nombres &&
                    <div className="label">
                        <span className="label-text-alt text-error">
                            {errors.Nombres.message}
                        </span>
                    </div>
                }
            </div>

            <div className="form-control">
                <span className='label-text font-bold text-md mb-2'>Documento *</span>

                <select {...register('TipoIdentificacion')} className="select bg-white select-bordered w-full">
                    <option value="CEDULA" >Cedula</option>
                </select>
            </div>

            <div className="form-control">
                <span className='label-text font-bold text-md mb-2'>Numero Documento *</span>

                <input
                    type="text"
                    inputMode='numeric'
                    className={cn('input input-bordered bg-white w-full', { 'input-error': errors.Identificacion })}
                    {...register('Identificacion')}
                />

                {
                    errors.Identificacion &&
                    <div className="label">
                        <span className="label-text-alt text-error">
                            {errors.Identificacion.message}
                        </span>
                    </div>
                }
            </div>

            <div className="form-control">
                <span className='label-text font-bold text-md mb-2'>Fecha Nacimiento *</span>

                <DateInput
                    maxDate={
                        dayjs().subtract(18, 'years').subtract(2, 'days').toDate()
                    }
                    className='input input-bordered bg-white w-full'
                    selected={
                        dayjs().subtract(18, 'years').subtract(2, 'days').toDate()
                    }
                    onChange={ e => { setValue('FechaNacimiento', e) } }
                    placeholderText='01/02/2016'
                />

                {
                    errors.FechaNacimiento &&
                    <div className="label">
                        <span className="label-text-alt text-error">
                            {errors.FechaNacimiento.message}
                        </span>
                    </div>
                }
            </div>

            <div className="form-control">
                <span className='label-text font-bold text-md mb-2'>Correo *</span>

                <input
                    type="email"
                    className={cn('input input-bordered bg-white w-full', { 'input-error': errors.Correo })}
                    {...register('Correo')}
                />

                {
                    errors.Correo &&
                    <div className="label">
                        <span className="label-text-alt text-error">
                            {errors.Correo.message}
                        </span>
                    </div>
                }
            </div>

            <div className="form-control">
                <span className='label-text font-bold text-md mb-2'>Celular *</span>

                <PhoneInput
                    defaultCountry='CO'
                    countries={['CO', 'US', 'HN', 'ES']}
                    placeholder="314 9998099"
                    value={getValues('Celular')}
                    onChange={(phone: string) => { setValue('Celular', phone) }}
                    className='input  input-bordered bg-white input-primary w-full max-w-xs'
                />
                {
                    errors.Celular &&
                    <div className="label">
                        <span className="label-text-alt text-error">
                            {errors.Celular.message}
                        </span>
                    </div>
                }
            </div>

        </div>

    </div>
  )
}
