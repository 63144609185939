import { Navigate, useNavigate } from 'react-router-dom'
import { type SubmitHandler, useForm } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'
import cn from 'classnames'

import { useAuthStore } from '../../stores/auth.store'
import { schemaAuthLogin } from '../../validation/auth'

interface FormData {
  correo: string
  password: string
}

export const Login = (): JSX.Element => {
  const navigate = useNavigate()
  const user = useAuthStore(state => state.user)
  const login = useAuthStore(state => state.login)

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors }
  } = useForm<FormData>({
    resolver: joiResolver(schemaAuthLogin)
  })

  if (user) {
    return <Navigate to="/form" replace />
  }

  const onSubmit: SubmitHandler<FormData> = (data) => {
    const isLogin = login(data)

    if (isLogin) {
      navigate('/form')
      return
    }

    setError('password', {
      type: 'custom',
      message: 'Correo o Contraseña no son válidos'
    })
  }

  return (
    <div className="flex items-start justify-center bg-white rounded h-[90vh] pt-[25%] md:pt-[10%] px-4 md:mt-4">

        <div className="w-[90%] md:w-[30%] shadow bg-white">
            <h1 className='text-2xl font-bold text-primary text-center my-2'>
                Inicia sesión
            </h1>

            <form onSubmit={handleSubmit(onSubmit)}>
                <div className='px-3 py-2 flex flex-col gap-3'>

                    <div className="form-control">
                        <span className='label-text font-bold text-md mb-2'>Correo *</span>

                        <input
                            type="text"
                            className={cn('input input-bordered bg-white w-full', { 'input-error': errors.correo })}
                            {...register('correo')}
                        />

                        {
                            errors.correo &&
                            <div className="label">
                                <span className="label-text-alt text-error">
                                    {errors.correo.message}
                                </span>
                            </div>
                        }
                    </div>

                    <div className="form-control">
                        <span className='label-text font-bold text-md mb-2'>Contraseña *</span>

                        <input
                            type="password"
                            className={cn('input input-bordered bg-white w-full capitalize', { 'input-error': errors.password })}
                            {...register('password')}
                        />

                        {
                            errors.password &&
                            <div className="label">
                                <span className="label-text-alt text-error">
                                    {errors.password.message}
                                </span>
                            </div>
                        }
                    </div>

                    <div className="text-center">
                        <button
                            type='submit'
                            className='btn btn-sm btn-primary mx-auto my-4'
                        >
                            Continuar
                        </button>
                    </div>

                </div>
            </form>
        </div>

    </div>
  )
}
