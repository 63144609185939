import { useEffect, useRef, useState } from 'react'
import { Wizard } from 'react-use-wizard'
import { useFormStore } from '../../stores/form.store'

import {
  Codeudores,
  DatosPersonales,
  Egresos,
  Ingresos,
  Propiedad,
  Resultado
} from './steps'
import { Footer } from './Footer'
// import { Loading } from '../../components'
// import { useAuthState } from 'react-firebase-hooks/auth'
// import { firebaseAuth } from '../../firebase/setup'

export const Form = (): JSX.Element | null => {
  const initialized = useRef(true)
  // const [user, loadingUser] = useAuthState(firebaseAuth)
  const [currentStep, setCurrentStep] = useState(0)

  const totalSolicitantes = useFormStore((state) => state.totalSolicitantes)
  const currentSolicitante = useFormStore(state => state.getCurrentSolicitante())

  const setSolicitantes = useFormStore(state => state.setSolicitante)
  const setTarget = useFormStore(state => state.setTarget)
  const setOrigen = useFormStore(state => state.setOrigen)

  useEffect(() => {
    if (initialized.current) {
      if (totalSolicitantes === 0) {
        // const phone = user?.phoneNumber ?? ''
        const newSolicitante = setSolicitantes('TITULAR', '')
        setTarget(newSolicitante)
      }

      const params = new URLSearchParams(window.location.search)
      const idOrigen = params.get('IdOrigen') ?? ''

      setOrigen(idOrigen)

      initialized.current = false
    }
  }, [])

  // if (loadingUser) {
  //   return <Loading />
  // }

  return (
    <div className="flex items-center justify-center bg-white rounded pt-[10%] md:pt-[6%] px-4">
      <div className="shadow-2xl w-full bg-white md:w-[62%] flex flex-col min-h-[70dvh] md:py-8 py-2  ">
        <h3 className="text-center font-bold text-xl mt-2">
          {
            currentStep === 4
              ? 'Ingresa la información de la propiedad'
              : (

                  currentStep === 5
                    ? ''
                    : (

                        currentSolicitante?.TipoSolicitante === 'TITULAR'
                          ? 'Completa toda la información solicitada.'
                          : `Editando a ${
                      currentSolicitante?.Nombres.length > 15
                      ? `${currentSolicitante?.Nombres.substring(0, 12)}...`
                      : currentSolicitante?.Nombres
                    }`
                      )
                )
          }
        </h3>

        <Wizard
          startIndex={0}
          onStepChange={setCurrentStep}
          footer={<Footer />}
        >
          <DatosPersonales />

          <Ingresos />

          <Egresos />

          <Codeudores />

          <Propiedad />

          <Resultado />
        </Wizard>

      </div>
    </div>
  )
}
