import { create, type StateCreator } from 'zustand'
import { type Asesor, asesores } from '../data/asesores'
import { persist } from 'zustand/middleware'

interface AuthStore {
  asesores: Asesor[]
  user: Asesor | null

  login: (payload: Pick<Asesor, 'correo' | 'password'>) => boolean
  logout: () => void
}

const storeAuth: StateCreator<AuthStore> = (set, get) => ({
  asesores,
  user: null,

  login: ({ correo, password }) => {
    const asesores = get().asesores

    const asesor = asesores.find(e => e.correo === correo)

    if (!asesor) return false

    if (asesor.password === password) {
      set((state) => ({
        ...state,
        user: asesor
      }))

      return true
    }

    return false
  },
  logout: () => {
    set((state) => ({
      ...state,
      user: null
    }))
  }
})

export const useAuthStore = create<AuthStore>()(
  persist(
    storeAuth,
    { name: 'auth-store' }
  )
)
