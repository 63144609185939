import Joi from 'joi'
import esMessages from '../locales/validation/es'

const custom = Joi.defaults(schema => schema.options({
  messages: {
    es: esMessages
  },
  errors: {
    language: 'es'
  }
}))

export const schemaAuthLogin = custom.object({
  correo: Joi.string().required().email({ tlds: false }),
  password: Joi.string().required()
})
