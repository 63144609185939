import { useRef, useState, memo, useEffect } from 'react'
import { useDebounce } from 'usehooks-ts'

import cn from 'classnames'

interface Props {
  items: string[]
  onInput: (value: string) => void
  onSelect: (value: string) => void
  defaultValue?: string
  placeholder?: string
}

const AutoComplete: React.FC<Props> = memo(({ items, onInput, onSelect, defaultValue = '', placeholder = 'Buscar...' }) => {
  const ref = useRef<HTMLDivElement>(null)
  const [open, setOpen] = useState(false)
  const [value, setValue] = useState<string>(defaultValue)

  const debounce = useDebounce(value, 600)

  useEffect(() => {
    onInput(value)
  }, [debounce])

  return (
    <div
        className='dropdown w-full'
        ref={ref}
    >
        <input
            type="text"
            className="input bg-white input-bordered w-full"
            value={value}
            onChange={({ target }) => {
              setOpen(true)
              setValue(target.value)
            }}
            placeholder={placeholder}
            tabIndex={0}
        />

        <div className={
            cn('dropdown-content bg-base-200 dark:bg-white z-50 top-14 max-h-96 overflow-auto flex-col rounded-md w-full', {
              'hidden': !open
            })
        }>
            <ul
                // style={{ width: ref.current?.clientWidth }}
                className='menu menu-compact w-full'
            >
                {
                    items.length > 0
                      ? items.map((item, index) => (
                        <li
                            tabIndex={index + 1}
                            key={`autocomplete_item_${index}`}
                            onClick={() => {
                              setOpen(false)
                              setValue(item)
                              onSelect(item)
                            }}
                        >
                            <button>{item}</button>
                        </li>
                      ))
                      : <li> <button>Sin sugerencias</button> </li>
                }
            </ul>
        </div>
    </div>
  )
})

AutoComplete.displayName = 'AutoComplete'

export { AutoComplete }
