import {
  Navigate,
  Outlet,
  createBrowserRouter
} from 'react-router-dom'

import { DefaultLayout } from '../layouts/DefaultLayout'

import {
  // Home,
  Form,
  Login
} from '../pages'
import { useAuthStore } from '../stores/auth.store'
// import { Loading } from '../components'

const ProtectedRoutes = (): JSX.Element | null => {
  const user = useAuthStore(state => state.user)

  return (user != null) ? <Outlet /> : <Navigate to="/login" replace />
}

export const router = createBrowserRouter([
  {
    path: '/login',
    element: <DefaultLayout> <Login /> </DefaultLayout>
  },
  {
    element: <ProtectedRoutes />,
    children: [
      {
        path: 'form',
        element: <DefaultLayout> <Form/> </DefaultLayout>
      }
    ]
  },
  // {
  //   path: '/',
  //   element: <DefaultLayout> <Form/> </DefaultLayout>
  // },
  {
    path: '*',
    element: <Navigate to="/login" replace />
  }
])
