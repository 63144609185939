export interface Asesor {
  nombre: string
  correo: string
  password: string
  idOrigen: string
}

export const asesores: Asesor[] = [
  {
    correo: 'juandavid9286@hotmail.com',
    nombre: 'Juan David Roncancio Cardenas',
    idOrigen: '9ba0cd55-fff8-4936-b429-3af36f2fa001',
    password: 'viviend@total#'
  },
  {
    correo: 'connyvargas03@hotmail.com',
    nombre: 'Constanza Pilar Vargas Fernández',
    idOrigen: '9ba0cd87-7b6a-4f9f-8eb6-0b7714312c8b',
    password: 'viviend@total#'
  },
  {
    correo: 'ingrid.ferreira@viviendatotal.co',
    nombre: 'Ingrid Milena Ferreira Piñeros',
    idOrigen: '9ba0cd88-b682-4c00-86a4-3e39391b6490',
    password: 'viviend@total#'
  },
  {
    correo: 'creditohipotecario@viviendatotal.co',
    nombre: 'Laura Yadith Iguavita',
    idOrigen: '9ba0cd89-aab5-409e-be7e-346e908157c4',
    password: 'viviend@total#'
  },
  {
    correo: 'crediatlasco@gmail.com',
    nombre: 'Fabio Miguel Madrid',
    idOrigen: '9ba70572-1f04-42ee-84be-f5030b7ce488',
    password: 'viviend@total#'
  },
  {
    correo: 'solufinanzasoficial@gmail.com',
    nombre: 'Martha Martínez',
    idOrigen: '9be3507b-14e3-4cf7-87e2-7a79d9defcff',
    password: 'viviend@total#'
  }
]
